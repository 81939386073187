import { useEffect, useState } from "react";
import http from "../utils/http";
import { useNavigate } from "react-router-dom";

function History() {
  const navigate = useNavigate();
  const [histories, setHistories] = useState([]);

  useEffect(() => {
    getHistories();
  }, []);

  const getHistories = () => {
    http
      .get("/logger")
      .then((res) => {
        console.log(res);
        setHistories(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDateFormated = (unixTime) => {
    const date = new Date(unixTime);
    const year = date.getFullYear();
    const month = date.getMonth(); // Note: Months are 0-indexed (0 - January, 11 - December)
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    return `${year}-${month + 1}-${day} ${hours}:${minutes}:${seconds}`;
  };

  return (
    <>
      <div className="w-full flex flex-row">
        <div className="w-[80%] m-auto mt-20">
          <div className="card bg-base-100 shadow-xl">
            <div className="card-body">
              <div className="flex flex-row">
                <button
                  className="btn btn-square"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
                <h2 className="card-title ml-4">Riwayat</h2>
              </div>
              <div className="overflow-x-auto">
                <table className="table table-zebra">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Waktu</th>
                      <th>Email</th>
                      <th>Nama File</th>
                      <th>No Awal</th>
                      <th>No Akhir</th>
                    </tr>
                  </thead>
                  <tbody>
                    {histories.map((history, index) => (
                      <>
                        <tr>
                          <th>{index}</th>
                          <td>{getDateFormated(history.generatedAt)}</td>
                          <td>{history.email}</td>
                          <td>{history.fileName}</td>
                          <td>{history.startIndex}</td>
                          <td>{history.endIndex}</td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="card-actions justify-end">
                {/* <button className="btn btn-primary">Buy Now</button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default History;
