export const parseExcelDate = (date) => {
  // Calculate the number of days between Excel's starting date (January 1, 1900) and JavaScript's starting date (January 1, 1970)
  const daysOffset = 25569; // This is the number of days between January 1, 1900, and January 1, 1970.

  // Convert the Excel date value to JavaScript timestamp (milliseconds since January 1, 1970)
  const excelTimestamp = (date - daysOffset) * 24 * 60 * 60 * 1000;

  // Create the JavaScript Date object
  const dateObject = new Date(excelTimestamp);

  // Format the date as "dd-mm-yyyy"
  const day = dateObject.getDate().toString().padStart(2, "0");
  const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
  const year = dateObject.getFullYear();
  const formattedDate = `${day}-${month}-${year}`;
  return formattedDate;
};
