import PitukuLogo from "../assets/pituku-square.jpeg";
import { parseExcelDate } from "../utils/dateParser";
import { rupiah } from "../utils/currency";
import ttd from "../assets/ttd-cap.jpeg";

const Sales = (props) => {
  const { invoices } = props;
  return (
    <>
      {invoices.map((invoice, index) => (
        <>
          <div
            id={`invoice-${index}`}
            className="bg-white p-5 rounded-lg shadow-md mt-8 mb-12"
            style={{
              // backgroundColor: "#f5f5f5",
              width: "297mm",
              minHeight: "210mm",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            key={index}
          >
            <div className="flex flex-row mt-2">
              <div className="mr-auto">
                <img src={PitukuLogo} className="w-28" alt="" />
              </div>
              <div className="ml-auto w-[35%]">
                <h1 className="text-3xl font-bold text-teal-500">INVOICE</h1>
                <p className="text-sm">
                  Tanggal Invoice: {parseExcelDate(invoice.tanggal)}
                </p>
                <p className="text-sm">Batas Waktu Invoice : {parseExcelDate(invoice.tanggalPelunasan)}</p>
                <p className="text-sm">No. Invoice {invoice.noTransaksi}</p>
              </div>
            </div>
            <div className="divider"></div>
            <div className="flex flex-row mt-2 mb-2">
              <div className="mr-auto w-[40%]">
                <p className="text-sm font-semibold">
                  PT Pituku Cordova International
                </p>
                <p className="text-sm">
                  Ruko Loka, Jl. Anggrek Ungu No.C-07, Rw. Buntu, Kec. Serpong,
                  Kota Tangerang Selatan, Banten 15310
                </p>
                <p className="text-sm">Email: marketing@pituku.id</p>
                <p className="text-sm">+6282246339932</p>
              </div>
              <div className="ml-auto w-[35%]">
                <p className="text-sm">Bill To:</p>
                <p className="text-sm font-semibold">{invoice.suplyer}</p>
              </div>
            </div>

            <div className="overflow-x-auto">
              <table className="table table-zebra border border-gray-300 mt-2">
                {/* head */}
                <thead>
                  <tr>
                    <th>Jenis Limbah</th>
                    <th>Kuantitas</th>
                    <th>Harga</th>
                    <th>Sub Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{invoice.barang}</td>
                    <td>{new Intl.NumberFormat("id-ID").format(invoice.qty)}</td>
                    <td>{rupiah(invoice.harga)}</td>
                    <td>{rupiah(invoice.jumlahHarga)}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="flex flex-row mt-2">
              <div className="mr-auto w-[40%]">
                <p className="text-sm font-semibold mb-1 mt-2">Note :</p>
                <textarea
                  className="textarea textarea-bordered w-full"
                  placeholder="Catatan"
                ></textarea>
                <p className="text-sm mt-2">
                  Pembayaran dapat di transfer ke rekening :
                </p>
                <p className="text-sm">BCA - 4977777614 an. PITUKU CORDOVA</p>
                <p className="text-sm">
                  BRI - 050901002267309 an. PITUKU CORDOVA
                </p>
                <p className="text-sm">
                  MANDIRI - 1220011497487 an. PITUKU CORDOVA
                </p>
              </div>
              <div className="ml-auto mt-4 w-[35%]">
                <div className="flex flex-row">
                  <div className="mr-auto">
                    <p className="text-sm">Sub Total:</p>
                    <p className="text-sm">Pajak:</p>
                    <p className="text-sm">Diskon:</p>
                    <p className="text-sm">Biaya Pengiriman:</p>
                    <p className="text-sm font-semibold">Total:</p>
                  </div>
                  <div className="ml-auto">
                    <p className="text-sm">{rupiah(invoice.jumlahHarga)}</p>
                    <p className="text-sm">Rp 0</p>
                    <p className="text-sm">Rp 0</p>
                    <p className="text-sm">Rp 0</p>
                    <p className="text-sm font-semibold">
                      {rupiah(invoice.jumlahHarga)}
                    </p>
                  </div>
                </div>
                <p className="ml-auto mt-20 text-center">
                  <img className="m-auto" src={ttd} width="200px" />
                  <p>( Faiz Fauzani Rinaldy )</p>
                </p>
              </div>
            </div>
          </div>
        </>
      ))}
    </>
  );
};


export default Sales;