import { Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Home from "./pages/Home";
import Invoice from "./pages/Invoice";
import History from "./pages/History";

function App() {

  return (
    <>
      <Routes>
        <Route path="/" element={ <Home/> } />
        <Route path="/history" element={ <History/> } />
        <Route path="/d/:invoiceId" element={ <Invoice /> } />
      </Routes>
    </>
  );
}

export default App;
