import html2canvas from "html2canvas";
import JsPDF from "jspdf";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { read, utils } from "xlsx";
import Purchase from "../components/Purchase";
import Sales from "../components/Sales";
import { asyncForEach } from "../utils/asyncForEach";
import { rupiah } from "../utils/currency";
import { parseExcelDate } from "../utils/dateParser";
import http from "../utils/http";
import { useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [startIndex, setStartIndex] = useState(0);
  const [maxInvoice, setMaxInvoice] = useState(0);
  const [invoices, setInvoices] = useState([]);
  const [invoiceType, setInvoiceType] = useState("SALES");
  const [renderedInvoices, setRenderedInvoices] = useState([]);
  const [isExporting, setIsExporting] = useState(false);
  const [fileName, setFileName] = useState('');
  const [templateName, setTemplateName] = useState('purchase1');
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [progressExporting, setProgressExporting] = useState(0);

  const handleImport = ($event) => {
    setIsLoadingFile(true);
    const files = $event.target.files;
    setFileName(files[0].name);
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;

        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);

          let invoiceData = [];
          for (let i = 2; i < 1 + 2; i++) {
            invoiceData.push({
              barang: rows[i]["Barang"],
              code: rows[i]["Code"],
              codeBarang: rows[i]["Code Barang"],
              harga: rows[i][" Harga"],
              jumlahHarga: rows[i][" Jumlah Harga"],
              noTransaksi: rows[i]["No Transaksi"],
              pelunasan: rows[i]["Pelunasan"],
              qty: rows[i][" Qty"],
              suplyer: rows[i]["Suplyer"],
              tanggal: rows[i]["Tanggal"],
              tanggalPelunasan: rows[i]["Tanggal Pelunasan"],
            });
          }
          setRenderedInvoices(invoiceData);

          invoiceData = [];
          for (let i = 0; i < rows.length; i++) {
            invoiceData.push({
              barang: rows[i]["Barang"],
              code: rows[i]["Code"],
              codeBarang: rows[i]["Code Barang"],
              harga: rupiah(rows[i][" Harga"]),
              jumlahHarga: rupiah(rows[i][" Jumlah Harga"]),
              noTransaksi: rows[i]["No Transaksi"],
              pelunasan: rows[i]["Pelunasan"],
              qty: `${new Intl.NumberFormat("id-ID").format(
                rows[i][" Qty"]
              )}`,
              suplyer: rows[i]["Suplyer"],
              tanggal: parseExcelDate(rows[i]["Tanggal"]),
              tanggalPelunasan: parseExcelDate(rows[i]["Tanggal Pelunasan"]),
            });
          }
          setInvoices(invoiceData);
        }
      };
      reader.readAsArrayBuffer(file);
    }
    setIsLoadingFile(false);
  };

  const generatePDF = async () => {
    setIsExporting(true);
    let docElement = [];
    const totalElements = invoices.length;

    await asyncForEach(invoices, async (invoice, index) => {
      let dataElement = document.getElementById(`invoice-${index}`);
      const canvas = await html2canvas(dataElement);
      docElement.push(canvas.toDataURL("image/png", 0.5));
      const progress = ((index + 1) / totalElements) * 100;
      setProgressExporting(Math.round(progress) / 2);
      console.log(`Progress Canvas: ${Math.round(progress) / 2}%`);
    });

    const pdf = new JsPDF({
      orientation: "l",
      unit: "mm",
      format: "a4",
      compress: true,
    });

    await asyncForEach(docElement, async (element, index) => {
      pdf.addImage(element, "JPEG", 0, 0, 297, 210);
      pdf.addPage();
      const progress = ((index + 1) / totalElements) * 100;
      setProgressExporting(Math.round(progress) / 2 + 50);
      console.log(`Progress PDF: ${Math.round(progress) / 2 + 50}%`);
    });

    pdf.save(`invoice.pdf`);
    setIsExporting(false);
  };

  const submit = async () => {
    setIsExporting(true);

    const limitInvoices = invoices.slice(startIndex - 1, maxInvoice);
    console.log(limitInvoices);
    http
      .post("/generate-pdf", {
        email,
        fileName,
        startIndex: parseInt(startIndex),
        endIndex: parseInt(maxInvoice),
        type: invoiceType,
        invoices: limitInvoices,
        template: templateName
      })
      .then((res) => {
        toast.success("🚀 Invoice sedang diproses!", {
          position: "bottom-left",
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: "slide",
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error("😔 Maaf terjadi ke gagalan!", {
          position: "bottom-left",
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: "slide",
        });
      })
      .finally(() => {
        setIsExporting(true);
      });
  };

  return (
    <>
      <ToastContainer />
      <div className="grid grid-cols-12 gap-2 bg-gray-50">
        {/* sidebar */}
        <div className="col-span-3 bg-white p-5 border-gray-200 border h-[100vh] overflow-auto fixed">
          <h1 className="font-bold text-2xl mb-5">Invoice Generator</h1>
          <div className="form-control w-full max-w-xs mt-4">
            <label className="label">
              <span className="label-text font-bold">Tipe Invoice</span>
            </label>
            <select
              className="select select-bordered"
              onChange={(event) => setInvoiceType(event.target.value)}
            >
              <option defaultValue value="SALES">
                Sales
              </option>
              <option value="PURCHASE">Purchase</option>
            </select>
          </div>
          <div className="form-control w-full max-w-xs mt-4">
            <label className="label">
              <span className="label-text font-bold">Pilih sumber excel</span>
            </label>
            <input
              type="file"
              onChange={handleImport}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              className="file-input file-input-sm file-input-bordered w-full max-w-xs"
            />
            <label className="label">
              <span className="label-text-alt">
                Pastikan data yang ingin di import berada di sheet urutan
                pertama
              </span>
            </label>
          </div>
          <div className="form-control w-full max-w-xs mt-4">
            <label className="label">
              <span className="label-text font-bold">Email penerima</span>
            </label>
            <input
              type="email"
              placeholder="Type here"
              className="input input-bordered w-full max-w-xs"
              onChange={(event) => setEmail(event.target.value)}
            />
            <label className="label">
              <span className="label-text-alt">
                Invoice PDF akan dikirimkan ke email jika proses generator
                selesai
              </span>
            </label>
          </div>
          <div className="form-control w-full max-w-xs">
            <label className="label">
              <span className="label-text font-bold">Pilih template</span>
            </label>
            <select className="select select-bordered" onChange={(event) => setTemplateName(event.target.value)}>
              <option defaultValue>Default Pituku</option>
              <option value="purchase1">CV Global Perkasa Sejahtera</option>
              <option value="purchase2">CV Sinar Utama Makmur</option>
              <option value="purchase3">CV Sami Jaya Sejahtera</option>
              <option value="purchase4">Karya Dahono Makmur</option>
              <option value="purchase5">PT Zenith Material Indonesia</option>
              <option value="purchase7">PT Solusi Material Indonesia</option>
              <option value="purchase8">PT Buana Oasis Chemicals</option>
            </select>
          </div>
          <div className="form-control w-full max-w-xs mt-4">
            <label className="label">
              <span className="label-text font-bold">No awal</span>
            </label>
            <input
              type="number"
              placeholder="Type here"
              className="input input-bordered w-full max-w-xs"
              onChange={(event) => setStartIndex(event.target.value)}
            />
            <label className="label">
              <span className="label-text-alt">
                Dimulai dari baris berapa invoice akan digenerate
              </span>
            </label>
          </div>
          <div className="form-control w-full mt-4">
            <label className="label">
              <span className="label-text font-bold">No akhir</span>
            </label>
            <input
              type="number"
              placeholder="Type here"
              className="input input-bordered w-full max-w-xs"
              onChange={(event) => setMaxInvoice(event.target.value)}
            />
            <label className="label">
              <span className="label-text-alt">
                Sampai baris berapa invoice akan digenerate
              </span>
            </label>
          </div>
          <button
            className={`btn btn-primary mt-4 ${
              isExporting ? "btn-disabled" : ""
            }`}
            onClick={submit}
          >
            {isExporting ? "Exporting.." : "Export"}
          </button>
          <button className="btn btn-ghost ml-2" onClick={() => navigate('/history')}>Riwayat</button>
          {isExporting ? (
            <p className="text-sm mt-2 text-green-700">
              Invoice sedang diproses oleh server! 😊{" "}
            </p>
          ) : null}
        </div>
        <div className="col-span-9 p-2 ml-[35%]">
          {invoiceType === "SALES" ? (
            <Sales invoices={renderedInvoices} />
          ) : (
            <Purchase invoices={renderedInvoices} />
          )}
        </div>
      </div>
    </>
  );
}

export default Home;
