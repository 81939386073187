import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

function Invoice(props) {
  let { invoiceId } = useParams();

  useEffect(() => {
    window.location.href = `https://storage.googleapis.com/pituku-web-prod/invoice-generator/${invoiceId}`;
  }, [invoiceId]);
}

export default Invoice;
